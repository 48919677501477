import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import Sidebar from "../../components/Sidebar";
import myUtil, { IS_EMBED } from "../../helpers/cha-utils";
import BasicTextBox from "../../components/BasicTextBox";
import { copyOutline, trashBinOutline } from "ionicons/icons";
import Settings from "../../components/Settings";
import { BlockMath } from "react-katex";
import ResultSection from "../../components/ResultSection";

import "katex/dist/katex.min.css";
import ResultSection1 from "../../components/Result/ResultSection1";
import { useState } from "react";

const page_title = "Wh ⇨ Ah Converter";

const Index: React.FC = () => {
  const [decimalValue, setDecimalValue] = useState<number>(8);

  const inputChangeHandler = (
    controlId: number,
    user_input: string,
    user_value: number
  ) => {
    setControlItems((oldItems) => {
      return oldItems.map((item) => {
        item.input = item.id === controlId ? user_input : item.input;
        item.value = item.id === controlId ? +user_value : item.value;
        return item;
      });
    });
  };

  type controlItemsType = {
    id: number;
    input: string;
    value: number;
    label: string;
    subLabel: string;
    short: string;
  }[];

  const initialControlItems: controlItemsType = [
    {
      id: 1,
      input: "",
      value: 0,
      label: "Energy",
      subLabel: "Wh - watt-hours",
      short: "Wh",
    },
    {
      id: 2,
      input: "",
      value: 0,
      label: "Voltage",
      subLabel: "V - Volts",
      short: "V",
    },
  ];

  const [controlItems, setControlItems] =
    useState<controlItemsType>(initialControlItems);

  const controlItemsJSX = controlItems.map((item) => (
    <IonRow key={item.id}>
      <IonCol>
        <BasicTextBox
          controlId={item.id}
          type="number"
          label={item.label}
          subLabel={item.subLabel}
          short={item.short}
          shortWidth={50}
          textSize={3}
          input={item.input}
          value={item.value}
          onIonInput={inputChangeHandler}
        />
      </IonCol>
    </IonRow>
  ));

  const resultValue =
    controlItems[0].value && controlItems[1].value
      ? controlItems[0].value / controlItems[1].value
      : 0;

  const resultText = myUtil.formatNumber(resultValue, decimalValue);

  const resultJSX = (
    <ResultSection1
      subtitleTop="Energy Result in Amp-Hours"
      title={resultValue ? myUtil.formatNumber(resultValue, 2) + " Ah" : "?"}
      subtitleBottom={
        resultValue
          ? resultText + " Ah"
          : "Please enter both values to obtain a result."
      }
      subtitleBottomColor={!resultValue ? "danger" : ""}
    />
  );

  const clearButtonHandler = () => {
    setControlItems(initialControlItems);
  };

  const formulaJSX = (
    <h1
      style={{
        textAlign: "center",
        margin: "15px 0 0 0",
        color: "var(--ion-text-color)",
      }}
    >
      <BlockMath children="{Ah} = \frac{Wh}{V}"></BlockMath>
    </h1>
  );

  // Toast Functions
  const [present] = useIonToast();
  const showToast = (
    message: string,
    position: "top" | "middle" | "bottom",
    icon?: string
  ) => {
    present({
      message: message,
      duration: 1500,
      position: position,
      icon: icon,
    });
  };

  return (
    <>
      {!IS_EMBED && <Sidebar />}

      <IonPage id="main-content">
        {!IS_EMBED && (
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
              </IonButtons>
              <IonTitle>{page_title}</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent>
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-align-center">
              <IonCol size="12" sizeMd="6">
                <IonGrid>
                  {controlItemsJSX}

                  <IonRow>
                    <IonCol>{resultJSX}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        onClick={() => {
                          myUtil.copyToClipboard(resultText + " Ah");
                          showToast(
                            resultText + " Ah — copied to the clipboard!",
                            "bottom",
                            copyOutline
                          );
                        }}
                      >
                        <IonIcon icon={copyOutline} slot="start"></IonIcon>
                        <IonLabel>Copy</IonLabel>
                      </IonButton>
                    </IonCol>
                    <IonCol className="ion-text-right">
                      <IonButton color="danger" onClick={clearButtonHandler}>
                        <IonIcon icon={trashBinOutline} slot="start"></IonIcon>
                        <IonLabel>Reset</IonLabel>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <ResultSection
                  title="Formula"
                  color="primary"
                  subtitleBottom="Convert watt-hour (Wh) to amp-hour (Ah)"
                  content={formulaJSX}
                />
                <IonCard>
                  <Settings
                    decimalValue={decimalValue}
                    onRangeUpdated={(v) => setDecimalValue(+v)}
                  ></Settings>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Index;
