import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import myUtil, { IS_EMBED } from './helpers/cha-utils';

import { calculator, calculatorOutline } from 'ionicons/icons';

import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import ChaTabListType from './types/TabListType';
import { useState } from 'react';

const tabURLs: ChaTabListType[] = [
  { label: "wh-to-ah", name: "Wh ⇨ Ah", page: <Tab1 /> },
  { label: "ah-to-wh", name: "Ah ⇨ Wh", page: <Tab2 /> },
]


setupIonicReact();

myUtil.setupDefaultDarkMode();

const App: React.FC = () => {

  const currentPathName = window.location.pathname.substring(1);
  const [selectedTab, setSelectedTab] = useState<string>(currentPathName);

  const handleTabsDidChange = async (event: CustomEvent) => {
    setSelectedTab(event.detail.tab);
  };

  const tabBarPosition = IS_EMBED
    ? "top"
    : "bottom";
  const tabBarColor = IS_EMBED ? "primary" : "";
  console.log(window.location.pathname);
  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs onIonTabsDidChange={handleTabsDidChange} >
          <IonRouterOutlet>
            {
              tabURLs.map((item) => (
                <Route exact path={`/` + item.label} key={item.label}>
                  {item.page}
                </Route>
              ))
            }
            <Route exact path="/">
              <Redirect to={`/` + tabURLs[0].label} />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot={tabBarPosition} color={tabBarColor}>
            {
              tabURLs.map((item) => (
                <IonTabButton tab={item.label} href={`/` + item.label} key={item.label}>
                  <IonIcon icon={selectedTab === item.label ? calculator : calculatorOutline} />
                  <IonLabel>{selectedTab === item.label ? <strong>{item.name}</strong> : item.name}</IonLabel>
                </IonTabButton>
              ))
            }
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
